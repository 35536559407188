   /* Mobile Styles */
  @media only screen and (max-width: 400px) {
    .header-img{
      max-width: 100vw;
    }

  }
  
  /* Tablet Styles */
  @media only screen and (min-width: 401px) and (max-width: 960px) {

    .header-img{
      padding: 50px; 
      display: flex; 
      justify-content: center; 
    }
  }

  body {
    margin: 0 auto;
}
  
  /* Desktop Styles */
  @media only screen and (min-width: 961px) {

    .wrapper{position: relative;
      min-height: 100vh;
      width: 960px; 
      background-color: rgba(255, 255, 255, 0.486);
      margin: 0 auto; 
      text-align: center; 
      padding-bottom: 2.5rem;
    }
    .header-img{
      padding-top: 50px;
    }
  }


  body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
  }
  

  .padding-bottom{
    padding-bottom: 50px; 
  }



  p{
    padding-bottom: 50px; 
  }


  .top-bar-home .dot {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #51A384;
    float: left;
    margin: 35px 10px 0 0;
  }