footer{
    position: relative;
    bottom: 0;
    width: 100%; 
    padding-bottom: 50px;
    padding-top: 60px;
    background-color: #171515f0;
    text-align: center; 
}

footer{
  a{
    color: white; 
    text-decoration: none; 
  }
}

footer{
    img{
        width: 32px; 
        height: auto;
        border-radius: 40px;
        box-shadow: 7px 7px 3px #1f1f1f;
        margin-right: 10px; 
    }
}




  /* Mobile Styles */
  @media only screen and (max-width: 400px) {
    .wrapper{
        position: relative;
        max-width: 100vw;
        margin: 0 auto; 
        min-height: 100vh;
        padding-bottom: 2.5rem;
    }

  }
  
  /* Tablet Styles */
  @media only screen and (min-width: 401px) and (max-width: 960px) {
    .wrapper{
        position: relative;
        width: 90vw;
        margin: 0 auto; 
        min-height: 100vh;
        padding-bottom: 2.5rem;
    }
  }
  
  /* Desktop Styles */
  @media only screen and (min-width: 961px) {
    .wrapper{
        position: relative;
        width: 75vw;
        margin: 0 auto; 
        min-height: 100vh;
        padding-bottom: 2.5rem;
    }
  }


  footer{
    div{
      width: calc(33.333333% - 40px);
      height: 1px;
      background: white;
      position: absolute;
      display: block;
      top: 50%; 
    }
  }

  .div-right{
    right: 0; 
  }

  #footer-content{
     display: flex; 
     justify-content: center; 
  }