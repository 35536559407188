@import url('https://fonts.googleapis.com/css?family=Manjari&display=swap');

$backgroundColor: #ffffff;
$bodyColor: #000000;
$bodyFont: 'Manjari', sans-serif;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
}

.season-img{
  background-size: 314px 383px;
}

// Sun Moon
.anim750{
  transition: all 750ms ease-in-out;
}

#Awesome{
	position: relative;
	width: 180px;
	height: 180px;
	margin: 0 auto;
  
  backface-visibility: hidden;
}

#Awesome .sticky{
	transform: rotate(45deg);
}

#Awesome:hover .sticky{
	transform: rotate(10deg);
}

#Awesome .sticky{
	position: absolute;
	top: 0;
	left: 0;
	width:180px;
	height: 180px;
}

#Awesome .reveal .circle{
	box-shadow: 0 1px 0px rgba(0,0,0,.15);
  
  font-family: 'helvetica neue', arial;
  font-weight: 200;
  line-height: 140px;
  text-align: center;
  
  cursor: pointer;
}

#Awesome .reveal .circle{
  background-image: url("/assets/images/bl/bl-space.png"); 
  background-size: cover; 
}

#Awesome .circle_wrapper{
	position: absolute;
	width: 180px;
	height: 180px;
	left: 0px;
	top: 0px;
	overflow: hidden;
}

#Awesome .circle{
	position: absolute;
	width: 140px;
	height:  140px;
	margin: 20px;
	
	border-radius: 999px;
}

#Awesome .back{
	height: 10px;
	top: 30px;
}

#Awesome:hover .back{
	height: 90px;
	top: 110px;
}

#Awesome .back .circle{
	margin-top: -130px;
	background-color: #fbec3f;

	background-image: -webkit-linear-gradient(bottom, rgba(251,236,63,.0), rgba(255,255,255,.8));
}

#Awesome:hover .back .circle{
	margin-top: -50px;
}

#Awesome .front{
	height: 150px;
	bottom: 0;
	top: auto;
}

#Awesome:hover .front{
	height: 70px;
	
}

#Awesome .front .circle{
	margin-top: -10px;
  background-image: url("/assets/images/bl/bl-sun.png"); 
  background-size: cover; 
  background-repeat: no-repeat; 
}

#Awesome h4{
  font-family: 'helvetica neue', arial;
  font-weight: 200;
  text-align: center;
	position: absolute;
	width: 180px;
	height: 140px;
  line-height: 140px;
	
	transition: opacity 50ms linear 400ms;
}

#Awesome:hover h4{
	opacity: 0;
	
	transition: opacity 50ms linear 300ms;
}

#Awesome:hover .front .circle{
	margin-top: -90px;
	background-color: transparent; 
	background-position: 0 150px;
}