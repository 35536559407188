.done{
 p{
    text-decoration: line-through;
    display: inline; 
 }
 img{
     border: 3px dotted rgb(255, 226, 64); 
     border-radius: 100%; 
 }
 img:hover{
     border: 5px solid rgba(0, 0, 0, 0.794); 
     .bucketlist-complete{
         display: initial; 
     }
 }
}

.undone{
    img{
        opacity: 0.3;
    }
}

.bucketlist-container {
    position: relative;
    text-align: center;
    color: black;
  }

  .bucketlist-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .bucketlist-contain {
      display: flex;
      justify-content: center;
    position: relative;
  }
  
  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .bucketlist-contain:hover .image {
    opacity: 0.3;
  }
  
  .bucketlist-contain:hover .middle {
    opacity: 1;
  }
  
  .text {
    color: black;
    font-size: 2rem;
  }

  .card-inner{
    margin-top: 20px; 
    font-size: 1.1rem; 
  }

  #bucketlist-symbol{
    text-decoration: none; 
    display: inline; 
  }

  
  /* Tablet Styles */
  @media only screen and (min-width: 401px) and (max-width: 960px) {
    .text {
        font-size: 90%;
      }
  }
  

