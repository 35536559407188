   .container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .flex-cards {
    flex: 1 0 30%;
    margin: 5px;
    text-align: center; 
  }
  
  /* Mobile Styles */
  @media only screen and (max-width: 500px) {
    .card-header
    {
      img{
        width: 80vw; 
        height: auto;
      }
    } 
  }
  
  /* Tablet Styles */
  @media only screen and (min-width: 501px) and (max-width: 960px) {
    .card-header
    {
      img{
        width: 30vw; 
        height: auto;
      }
    } 
  }
  
  /* Desktop Styles */
  @media only screen and (min-width: 961px) {
    .card-header
    {
      img{
        width: 16vw; 
        height: auto;
      }
    } 

    .portfolio-container {
      position: relative;
    }
    
    .portfolio-image {
      display: block;
    }
    
    .portfolio-overlay {
      position: absolute;
      top: 0;
      left: 10%;
      opacity: 0;
      transition: .5s ease;
    }
    
    .portfolio-overlay-pp{
      position: absolute;
      top: 0;
      left: 10%;
      opacity: 0;
      transition: .1s ease;
    }
    
    .portfolio-container:hover .portfolio-overlay-pp {
      opacity: 1;
    }
    
    .portfolio-container:hover .portfolio-overlay {
      opacity: 1;
    }
    
  }






